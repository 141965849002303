/** @format */

import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Login',
    meta: {
      title: '登录页面'
    },
    component: () => import('@/view/Login.vue')
  },
  {
    path: '/home',
    name: '主页',

    meta: {
      title: '主页'
    },
    component: () => import('@/view/home/HomeView.vue'),

    children: [
      {
        path: '',
        name: '首页',
        meta: {
          title: '首页'
        },
        component: () => import('@/view/admin/user/UserList.vue'),
        redirect: '/home/StorageView'
      },
      {
        path: 'StorageView',
        name: '存储点',
        meta: {
          title: '存储点'
        },
        component: () => import('@/view/storagePoint/StorageView.vue'),
        children: [
          {
            path: '',
            name: '存储点列表',
            meta: {
              title: '存储点列表'
            },
            component: () => import('@/view/storagePoint/storagePoint.vue')
          },
          {
            path: '/home/StorageView/storageLocation/',
            name: 'storageLocation',
            meta: {
              title: '存储点详情'
            },
            component: () => import('@/view/storagePoint/StorageLocation.vue')
          }
        ]
      },
      {
        path: 'sample',
        name: '样本库',
        meta: {
          title: '样本库'
        },
        component: () => import('@/view/sample/SampleView.vue')
      },

      {
        path: 'logs',
        name: '操作日志',
        meta: {
          title: '操作日志'
        },
        component: () => import('@/view/log/LogFiles.vue')
      },
      {
        path: 'storageCheckLog',
        name: '存储柜巡查日志',
        meta: {
          title: '存储柜巡查日志'
        },
        component: () => import('@/view/log/StorageCheckLog.vue')
      },
      {
        path: 'sampleInAndOutLog',
        name: '样本出入库日志',
        meta: {
          title: '样本出入库日志'
        },
        component: () => import('@/view/log/SampleInAndOutLog.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
