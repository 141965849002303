<template>
  <router-view />
</template>

<style lang="less">
html,
body,
#app {
  width: 100vw;
  height: 100vh;
  color: #2c3e50;
  font-family: Avenir, Helvetica, Arial, sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  padding-right: 0 !important;
  margin: 0;
  font-family: var(--font-family);
}

body::-webkit-scrollbar {
  display: none;
}
</style>
