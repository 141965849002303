/** @format */

import { createApp } from 'vue'
import {
  Button,
  Form,
  FormItem,
  Input,
  InputPassword,
  Modal,
  Menu,
  MenuItem,
  SubMenu,
  Layout,
  LayoutSider,
  LayoutHeader,
  LayoutContent,
  LayoutFooter,
  Dropdown,
  Breadcrumb,
  BreadcrumbItem,
  Table,
  InputNumber,
  List,
  ListItem,
  Textarea,
  RadioButton,
  RadioGroup,
  Radio,
  Spin,
  Tag,
  Select,
  SelectOption,
  Empty,
  Upload
} from 'ant-design-vue'
import App from './App.vue'
import router from './router'
import store from './store'
import PrimeVue from 'primevue/config'
import ToastService from 'primevue/toastservice'
import 'primevue/resources/themes/lara-light-indigo/theme.css'
import 'primevue/resources/primevue.min.css'
import 'ant-design-vue/dist/antd.css'
import 'primeicons/primeicons.css'
import FocusTrap from 'primevue/focustrap'
const app = createApp(App)
app
  .use(store)
  .use(router)
  .use(Button)
  .use(Form)
  .use(FormItem)
  .use(Input)
  .use(InputPassword)
  .use(Modal)
  .use(Menu)
  .use(MenuItem)
  .use(SubMenu)
  .use(Layout)
  .use(LayoutSider)
  .use(LayoutHeader)
  .use(LayoutContent)
  .use(LayoutFooter)
  .use(Dropdown)
  .use(Breadcrumb)
  .use(BreadcrumbItem)
  .use(Table)
  .use(InputNumber)
  .use(List)
  .use(ListItem)
  .use(Textarea)
  .use(RadioButton)
  .use(RadioGroup)
  .use(Radio)
  .use(Spin)
  .use(Tag)
  .use(Select)
  .use(SelectOption)
  .use(Empty)
  .use(Upload)
  .use(PrimeVue)
  .use(ToastService)
  .directive('focustrap', FocusTrap)
export default app
